<template>
  <div class="joblist py-2 text-center">
      <table class="tab w-100">
          <tr class="bg-white">
              <th>金 额</th>
              <th>月 份</th>
              <th>类 型</th>
              <th>操 作</th>
          </tr>
          <tr class="list border-bottom" v-for="(val, index) in data" :key="index">
              <td>{{ val.real_pay }}</td>
              <td>{{ val.produce_date }}</td>
              <td>{{ val.type == 0 ? '同工同酬' : '小时工' }}</td>
              <td @click="details(val.id, val.produce_date)">详 情 <i class="fa-solid fa-angles-right"></i></td>
          </tr>
      </table>
  </div>
  <jmodal v-if="showModal" @close="showModal=false">
      <template v-slot:title>
          {{ modalTitle }}
      </template>
      <template v-slot:content>
              <Info :ids="ids"></Info>
      </template>
  </jmodal>
</template>

<script setup>
import { ref } from 'vue'
import Info from '@/components/user/joblist/info.vue'
const showModal = ref(false)
const modalTitle = ref()
const data = ref([])
const a = 5
for(var i=0; i<a; i++) {
    data.value.push({
        id: 100 + i,
        real_pay: 5600, //工资金额  float
        produce_date: '2022-03', //产生月 string
        type: 1 //类型  0同工同酬，1小时工
    })
}
const ids = ref()
function details(id, name) {
    modalTitle.value = name
    ids.value = id
    showModal.value = true
}
</script>

<style scpoed>
.joblist .tab tr:first-child {
    padding: 3px 0;
    background-color: rgba(255, 255, 255, 0.534);
}
.joblist .tab tr:first-child th {
    padding: 3px 0;
    font-weight: 500;
}
.joblist .tab .list td,i {
    padding-top: 4px;
    color: rgb(100, 100, 100);
}
</style>