<template>
<div class="list-info container">
    <div class="row my-2">
        <div class="col-5 text-end">姓 名</div><div class="col">{{ data.user.name }}</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">底 薪</div><div class="col">{{ data.base_salary }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">应出勤（天）</div><div class="col">{{ data.should_attendance_day }} 天</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">应出勤（小时）</div><div class="col">{{ data.should_attendance_times/60 }} 小时</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">实出勤（天）</div><div class="col">{{ data.real_attendance_day }} 天</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">应出勤（小时）</div><div class="col">{{ data.real_attendance_times/60 }} 小时</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">平时加班（小时）</div><div class="col">{{ data.day_add_times/60 }} 小时</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">周末加班</div><div class="col">{{ data.week_add_times/60 }} 小时</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">国假加班</div><div class="col">{{ data.countries_add_times/60 }} 小时</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">应 发</div><div class="col">{{ data.should_pay }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">总扣除</div><div class="col">{{ data.to_deduct }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">水电费</div><div class="col">{{ data.water_electricity }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">商 保</div><div class="col">{{ data.insurance }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">其它扣除</div><div class="col">{{ data.other }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">实发金额</div><div class="col">{{ data.real_pay }} 元</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">发生时间</div><div class="col">{{ data.produce_date }}</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">发放日期</div><div class="col">{{ data.created_time }}</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">操作员姓名</div><div class="col">{{ data.operations.name }}</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">类 型</div><div class="col">{{ data.type == 0 ? '同工同酬' : '小时工' }}</div>
    </div>
    <div class="row my-2">
        <div class="col-5 text-end">备 注</div>
        
    </div>
    <p class="text-break my-2">{{ data.remark }}</p>
</div>
        
</template>

<script setup>
    import { ref, defineProps, onMounted } from 'vue'
    import Axios from 'axios'
    const props = defineProps({
        ids: {
            type: Number,
            default: null
        }
    })
    const data = ref({
      id: 100, //number
      user: {
        id: 100, //用户id number
        name: 'simon' //用户 搜索
      },
      base_salary: 2280, //底薪
      should_attendance_day: 22, //应出勤 number
      should_attendance_times: 1500, //应出勤 小时   单位分钟  number
      real_attendance_day: 22, //实际出勤 number
      real_attendance_times: 15000, //实际出勤 小时 分钟
      day_add_times: 1800, //平时加班
      week_add_times: 600, //周末加班
      countries_add_times: 1200, //国假加班 
      should_pay: 6500, //应发金额 float 
      to_deduct: 350, //扣除总金额
      water_electricity: 100, //水电费
      insurance: 100, //保险
      other: 150, //其它扣除
      real_pay: 6150, //实发金额
      produce_date: '2022-03', //薪资产生时间   月或日或周期  2022-03-22  2022-03-10==2022-04-09 string
      created_time: '2022-04-23 08:20:00', //支付时间 string 搜索
      operations: {
        id: 100, //操作员id number
        name: 'jec ll' //操作员姓名 string 搜索
      },
      provider: {
        id: 100, //供应商id number
        name: '中介' //供应商名称 string 搜索
      },
      remark: '薪资产生时间薪资产生时间薪资产生时间薪资产生时间薪资产生时间薪资产生时间薪资产生时间', //备注
      type: 0, //类型  0同工同酬， 1小时工
      status: 0, //状态 默认0 成功 1失败 number
      deleted: 0 //默认0 正常   1已删除 number
})


onMounted(() => {
    //获取一条记录
    Axios.get('http://localhost:5000/companys/' + props.ids).then((response)=>{
        if(response.data.code == 0) {
        //   data.value = response.data.data
        }
    })
})
    


</script>

<style>
.list-info {
    font-size: 12px;
}
.list-info .row {
    color: rgb(100, 100, 100);
    border-bottom: 0.5px dotted rgb(100, 100, 100);
}
</style>